import { API } from 'aws-amplify';
import getCurrentUser from './getCurrentUser';

const httpRequestHandler = async (path, method, payload = undefined, queryParameters = undefined) => {
    const allowedMethods = ['get', 'post', 'put', 'delete']

    if (!allowedMethods.includes(method)) {
        throw new Error(`Invalid argument value: ${method}`);
    }
    
    let responseData;
    const user = await getCurrentUser();
    const apiName = process.env.REACT_APP_APIGatewayName;
    let myInit = {
        body: payload,
        headers: {
            Authorization: `Bearer ${user?.signInUserSession?.idToken?.jwtToken}`
        }
    };
    if (queryParameters) {
        myInit = { ...myInit, queryStringParameters: queryParameters };
    }

    try {
        switch (method) {
            case 'get': responseData = await API.get(apiName, path, myInit)
                break;
            case 'post': responseData = await API.post(apiName, path, myInit)
                break;
            case 'put': responseData = await API.put(apiName, path, myInit)
                break;
            case 'delete': responseData = await API.delete(apiName, path, myInit)
                break;
            default:
                responseData = null
        }
    } catch (e) {
        console.log(e);
        responseData = null
    }
    return responseData;
}

export default httpRequestHandler;