import { Auth } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import httpRequestHandler from '../utils/helpers/httpRequestHandler';

const UserContext = React.createContext({
    userData: null,
    uiSecret: null,
});

export const UserContextProvider = (props) => {
    const [userData, setUserData] = useState(null);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [uiSecret, setUiSecret] = useState(null);

    useEffect(() => {
        Auth.currentAuthenticatedUser()
            .then(async (user) => {
                if (user) {
                    setIsLoggedIn(true);
                    setUserData(user);
                    if (uiSecret == null) {
                        const res1 = await httpRequestHandler('/getuisettings', 'get');
                        setUiSecret(res1);
                    }
                }
            })
            .catch((e) => {
                console.log(e);
                setIsLoggedIn(false);
            }).finally(() => {
                setIsLoading(false)
            });
    }, []);
    const setUiSecretHandler = (uiSecret) => {
        setUiSecret(uiSecret);
    };
    const contextValue = {
        isLoggedIn,
        userData,
        uiSecret,
        setUiSecretHandler
    };

    if (isLoading) {
        return
    }

    return <UserContext.Provider value={contextValue}>{props.children}</UserContext.Provider>;
};

export default UserContext;
