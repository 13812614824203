import React from "react";
import { Container, Typography, Box, Button } from '@mui/material';

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // Log the error to an error reporting service
    console.error("ErrorBoundary caught an error", error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Container
          maxWidth="sm"
          style={{ textAlign: "center", marginTop: "10%" }}
        >
          <Box>
            <Typography variant="h1" color="error" gutterBottom>
              Oops!
            </Typography>
            <Typography variant="h4" gutterBottom>
              Something went wrong.
            </Typography>
            <Typography variant="body1" color="textSecondary" paragraph>
              The page you're looking for doesn't exist, or an unexpected error
              has occurred.
            </Typography>
            <Box mt={4}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={() => (window.location.href = "/")}
              >
                Return to Homepage
              </Button>
            </Box>
          </Box>
        </Container>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
