import React, { useContext, lazy, Suspense } from 'react';
import { Navigate, RouterProvider, createBrowserRouter } from 'react-router-dom';
import UserContext from './store/userContext';
import { COGNITO_GROUPS } from './utils/constants/optionList';
import ErrorBoundary from './utils/helpers/ErrorBoundary'; // Import ErrorBoundary

// Lazy-loaded components
const CaseManagers = lazy(() => import('./pages/caseManagers'));
const EditCaseManagerProfile = lazy(() => import('./pages/editCaseManagerProfile'));
const Login = lazy(() => import('./pages/auth/login'));
const Layout = lazy(() => import('./components/layout/layout'));
const ResetPassword = lazy(() => import('./pages/auth/resetPassword'));
const ClientProfileListing = lazy(() => import('./pages/lists/clientProfileListing'));
const ServiceProviderCompanyListing = lazy(() => import('./pages/lists/serviceProviderCompanyListing'));
const BAFFTransactionFileListing = lazy(() => import('./pages/lists/bAFFTransactionFileListing'));
const ClientProfile = lazy(() => import('./pages/clientProfile/clientProfile'));
const TransactionListing = lazy(() => import('./pages/lists/transactionListing'));
const RecursiveTransactionListing = lazy(() => import('./components/transaction/recursiveTransaction'));
const AddEditTransaction = lazy(() => import('./pages/transaction/addEditTransaction'));
const AddEditClientProfile = lazy(() => import('./pages/clientProfile/addEditClientProfile'));
const ViewPayeeDetailForm = lazy(() => import('./components/payee/viewPayeeDetailForm'));
const NotFoundPage = lazy(() => import('./pages/notFoundPage'));
const CaseManagerProfile = lazy(() => import('./pages/caseManagerProfile'));
const ReconciliationListing = lazy(() => import('./pages/lists/reconciliationListing'));
const PayeeListing = lazy(() => import('./pages/lists/payeeListing'));
const FundingSourceListing = lazy(() => import('./pages/lists/fundingSourceListing'));
const AddEditFundingSource = lazy(() => import('./pages/fundingsource/addEditFundingSource'));

const App = () => {
    const { isLoggedIn, userData } = useContext(UserContext);
    const isAllowed = userData?.signInUserSession?.accessToken?.payload['cognito:groups']?.includes(
        COGNITO_GROUPS[0]
    );
    const router = createBrowserRouter([
        {
            path: '/login',
            element: !isLoggedIn ? <Login /> : <Navigate to="/" />,
        },
        {
            path: '/reset-password',
            element: <ResetPassword />,
        },
        {
            path: '/',
            element: isLoggedIn ? (
                <ErrorBoundary>
                    <Layout />
                </ErrorBoundary>
            ) : (
                <Navigate to="/login" />
            ),
            children: [
                {
                    path: '',
                    element: <Navigate to="/profile" />,
                },
                {
                    path: 'profile',
                    element: (
                        <ErrorBoundary>
                            <CaseManagers />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'profile/edit',
                    element: (
                        <ErrorBoundary>
                            <EditCaseManagerProfile />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'casemanager/edit',
                    element: (
                        <ErrorBoundary>
                            <CaseManagerProfile />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'client',
                    children: [
                        {
                            path: '',
                            element: (
                                <ErrorBoundary>
                                    <ClientProfile />
                                </ErrorBoundary>
                            ),
                        },
                        {
                            path: 'add',
                            element: (
                                <ErrorBoundary>
                                    <AddEditClientProfile />
                                </ErrorBoundary>
                            ),
                        },
                        {
                            path: 'edit',
                            element: (
                                <ErrorBoundary>
                                    <AddEditClientProfile />
                                </ErrorBoundary>
                            ),
                        },
                    ],
                },
                {
                    path: 'transaction',
                    children: [
                        {
                            path: 'add',
                            element: (
                                <ErrorBoundary>
                                    <AddEditTransaction />
                                </ErrorBoundary>
                            ),
                        },
                        {
                            path: 'edit',
                            element: (
                                <ErrorBoundary>
                                    <AddEditTransaction />
                                </ErrorBoundary>
                            ),
                        },
                    ],
                },
                {
                    path: 'fundingsource',
                    children: [
                        {
                            path: 'add',
                            element: (
                                <ErrorBoundary>
                                    <AddEditFundingSource />
                                </ErrorBoundary>
                            ),
                        },
                        {
                            path: 'edit',
                            element: (
                                <ErrorBoundary>
                                    <AddEditFundingSource />
                                </ErrorBoundary>
                            ),
                        },
                    ],
                },
                {
                    path: 'wit/companies',
                    element: (
                        <ErrorBoundary>
                            <ServiceProviderCompanyListing />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/clients',
                    element: (
                        <ErrorBoundary>
                            <ClientProfileListing />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/transactions',
                    element: (
                        <ErrorBoundary>
                            <TransactionListing />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/recursivetransactions',
                    element: (
                        <ErrorBoundary>
                            <RecursiveTransactionListing />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/payeedetails',
                    element: (
                        <ErrorBoundary>
                            {isAllowed ? <PayeeListing /> : <NotFoundPage />}
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/baff-transaction-file',
                    element:(
                        <ErrorBoundary>
                            {isAllowed ? <BAFFTransactionFileListing /> : <NotFoundPage />}
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/payee',
                    element: (
                        <ErrorBoundary>
                            <ViewPayeeDetailForm />
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/reconciliations',
                    element: (
                        <ErrorBoundary>
                            {isAllowed ? <ReconciliationListing /> : <NotFoundPage />}
                        </ErrorBoundary>
                    ),
                },
                {
                    path: 'wit/fundingsources',
                    element: (
                        <ErrorBoundary>
                            <FundingSourceListing />
                        </ErrorBoundary>
                    ),
                },
            ],
        },
        {
            path: '*',
            element: (
                <ErrorBoundary>
                    <NotFoundPage />
                </ErrorBoundary>
            ),
        },
    ]);

    return (
        <Suspense fallback={<div>Loading...</div>}>
            <RouterProvider router={router} />
        </Suspense>
    );
};

export default App;
