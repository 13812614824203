module.exports = {
	TRANSACTION_STATUS: [
        { label: 'New', value: 'New', type: '1,5' },
        { label: 'Open', value: 'Open', type: '1,5' },
        { label: 'Needs Additional Information', value: 'Needs Additional Information', type: '1,5' },
        { label: 'Payment Info Requested', value: 'Payment Info Requested', type: '1,5' },
        { label: 'Approved for Payment', value: 'Approved for Payment', type: '1,5,3' },
        { label: 'Needs Additional Approval', value: 'Needs Additional Approval', type: '1,5' },
        { label: 'Payment Submitted', value: 'Payment Submitted', type: '1,5' },
        { label: 'Payment Info Received', value: 'Payment Info Received', type: '1,5' },
        { label: 'Paid', value: 'Paid', type: '1,2,5' },
        { label: 'Denied', value: 'Denied', type: '1,5' },
        { label: 'Cancelled', value: 'Cancelled', type: '1,5' },
        { label: 'Payment Failure', value: 'Payment Failure', type: '1,5' },
        { label: 'Pending', value: 'Pending', type: '1,5' },
        { label: 'Additional Review Approved', value: 'Additional Review Approved', type: '1,5' },
        { label: 'Additional Review Denied', value: 'Additional Review Denied', type: '1,5' },
        { label: 'Pre-approved', value: 'Pre-approved', type: '1,5' },
        { label: 'Stop Payment', value: 'Stop Payment', type: '1,3,4' },
        { label: 'Payment Returned', value: 'Payment Returned', type: '2' },
        { label: 'Partial Payment Returned', value: 'Partial Payment Returned', type: '2' },        
        { label: 'Recursive Pending', value: 'Recursive Pending', type: '4' },
        { label: 'Partially Paid', value: 'Partially Paid', type: '' },
    ],
    TRANSACTION_STATUS_REASON: [
        { label: 'Claim submitted after stipulated time frame', value: 'Claim submitted after stipulated time frame', type: '1' },
        { label: 'Does not fulfil program requirements', value: 'Does not fulfil program requirements', type: '1' },
        { label: 'Required documentation incorrect', value: 'Required documentation incorrect', type: '1' },       
        { label: 'Claim cancelled by agency', value: 'Claim cancelled by agency', type: '2' },
        { label: 'Client exited program', value: 'Client exited program', type: '2' },
        { label: 'Change of claim terms', value: 'Change of claim terms', type: '2' },
        { label: 'Documents unavailable', value: 'Documents unavailable', type: '2' },
        { label: 'Other resource used', value: 'Other resource used', type: '2' },                
        { label: 'Unknown', value: 'Unknown', type: '1,2' },
        { label: 'Other', value: 'Other', type: '1,2' },
    ],
    //Type 1:   (CL.LeaseStartDate == null) 
    //          (Navigation) 

    //Type 2:   (CL.LeaseStartDate - CurrentDate =< 90) AND CL.LeaseStartDate >= Invoice/StatementDate
    //          (Navigation/Deposits/Tenancy)

    //Type 3:   (CL.LeaseStartDate - CurrentDate =< 90) AND CL.LeaseStartDate < Invoice/StatementDate
    //          (Navigation/Deposits/Tenancy)

    //Type 4:   (CL.LeaseStartDate - CurrentDate > 90)
    //          (Tenancy)

    //Note: Removing type 3, and making type 3 options for type 2 as well(i.e. combining type 2 and 3 into 2).
    //Type 2:   (CL.LeaseStartDate - CurrentDate =< 90)
    //          (Navigation/Deposits/Tenancy)

    //Type 5: Show all types. 

    TRANSACTION_TYPE: [
        { label: 'Pet (vaccination and registration)', value: 'Pet (vaccination and registration)', type: '1,2,5' },
        { label: 'Transportation (bus passes, Uber and gas cards)', value: 'Transportation (bus passes, Uber and gas cards)', type: '1,2,5' },
		{ label: 'Car (repairs, registration and insurance)', value: 'Car (repairs, registration and insurance)', type: '1,2,5' },
        { label: 'Debt Repair (evictions and arrears utilities)', value: 'Debt Repair (evictions and arrears utilities)', type: '1,2,5' },
        { label: 'Storage/Moving Fees', value: 'Storage/Moving Fees', type: '1,2,5' },
        { label: 'Application Fees', value: 'Application Fees', type: '1,2,5' },
        { label: 'Documents', value: 'Documents', type: '1,2,5' },
        { label: 'Property Owner Bonus', value: 'Property Owner Bonus', type: '2,5' },
        { label: 'Holding Fees', value: 'Holding Fees', type: '2,5' },
        { label: 'Security Deposit', value: 'Security Deposit', type: '2,5' },
        { label: 'Furnishings', value: 'Furnishings', type: '2,5' },
        { label: 'Refrigerator', value: 'Refrigerator', type: '2,5' },
        { label: 'Utilities - deposit & setup', value: 'Utilities - deposit & setup', type: '2,5' },
        { label: 'Renters Insurance', value: 'Renters Insurance', type: '2,5' },        
        { label: 'Other', value: 'Other', type: '1,2,5' },
        { label: 'Prevention - Utility Bill', value: 'Prevention - Utility Bill', type: '2,4,5' },
        { label: 'Prevention - Unexpected Bill', value: 'Prevention - Unexpected Bill', type: '2,4,5' },	
		{ label: 'Prevention - Pet Vaccination and Registration', value: 'Prevention - Pet Vaccination and Registration', type: '2,4,5' },	
		{ label: 'Prevention - Transportation (bus passes, Uber and gas cards)', value: 'Prevention - Transportation (bus passes, Uber and gas cards)', type: '2,4,5' },
		{ label: 'Prevention - Car (repairs, registration and insurance)', value: 'Prevention - Car (repairs, registration and insurance)', type: '2,4,5' },
        { label: 'Prevention - Other', value: 'Prevention - Other', type: '2,4,5' },
        { label: 'Rental Arrears', value: 'Rental Arrears', type: 'HPSPDistrict1,HPSPDistrict2,HPSPDistrict3,HPSPDistrict4,HPSPDistrict5' },
        { label: 'Forward Rent', value: 'Forward Rent', type: 'HPSPDistrict1,HPSPDistrict2,HPSPDistrict3,HPSPDistrict4,HPSPDistrict5' },
        { label: 'Past Due Utility Bills', value: 'Past Due Utility Bills', type: 'HPSPDistrict1,HPSPDistrict2,HPSPDistrict3,HPSPDistrict4,HPSPDistrict5' },
        { label: 'Future Utility Bills', value: 'Future Utility Bills', type: 'HPSPDistrict1,HPSPDistrict2,HPSPDistrict3,HPSPDistrict4,HPSPDistrict5' },
        { label: 'Internet Bill Debt', value: 'Internet Bill Debt', type: 'HPSPDistrict1,HPSPDistrict2,HPSPDistrict3,HPSPDistrict4,HPSPDistrict5' },
        { label: 'Internet forward bills', value: 'Internet forward bills', type: 'HPSPDistrict1,HPSPDistrict2,HPSPDistrict3,HPSPDistrict4,HPSPDistrict5' },
        { label: 'Rental deposits', value: 'Rental deposits', type: 'HPSPDistrict4,HPSPDistrict5' },
        { label: 'Utility deposits', value: 'Utility deposits', type: 'HPSPDistrict4,HPSPDistrict5' },
        { label: 'Childcare', value: 'Childcare', type: 'HPSPDistrict5' },
        { label: 'Stability Payment', value: 'Stability Payment', type: 'Upskill1,Upskill2' },
        { label: 'Tuition Assistance', value: 'Tuition Assistance', type: 'Upskill1,Upskill2' },
        { label: 'Training Expense', value: 'Training Expense', type: 'Upskill1,Upskill2' },
        { label: 'Rental Assistance', value: 'Rental Assistance', type: 'Upskill1,Upskill2' },
        { label: 'Transportation', value: 'Transportation', type: 'Upskill1,Upskill2' },
        { label: 'Childcare', value: 'Childcare', type: 'Upskill1,Upskill2' },
        { label: 'Other', value: 'Other', type: 'Upskill1,Upskill2' },
    ],
    ACCOUNT_TYPE: ['Checking', 'Savings'],
    PAYMENT_RECEIPT_METHOD: ['ACH', 'Check'],
    UNIT_CITY: [
        'Aliso Viejo',
        'Anaheim',
        'Brea',
        'Buena Park',
        'Costa Mesa',
        'Cypress',
        'Dana Point',
        'Fountain Valley',
        'Fullerton',
        'Garden Grove',
        'Huntington Beach',
        'Irvine',
        'La Habra',
        'La Palma',
        'Laguna Beach',
        'Laguna Hills',
        'Laguna Niguel',
        'Laguna Woods',
        'Lake Forest',
        'Los Alamitos',
        'Mission Viejo',
        'Newport Beach',
        'Orange',
        'Placentia',
        'Rancho Santa Margarita',
        'San Clemente',
        'San Juan Capistrano',
        'Santa Ana',
        'Seal Beach',
        'Stanton',
        'Tustin',
        'Villa Park',
        'Westminster',
        'Yorba Linda',
    ],
    RACE: [
        'American Indian/Alaska Native/Indigenous',
        'Asian/Asian American',
        'Black/African American/African',
        'Native Hawaiian/Pacific Islander',
        'White',
        "Client doesn't know",
        'Client refused',
        'Data not collected',
    ],
    GENDER: [
        'Female',
        'Male',
        'A gender other than singularly female or male (e.g non-binary/genderfluid/agender/culturally specific gender)',
        'Transgender',
        'Questioning',
        "Client doesn't know",
        'Client refused',
        'Data not collected',
    ],
    ETHNICITY: [
        'Non-Hispanic/Non-Latin(a)(o)(x)',
        'Hispanic/Latin(a)(o)(x)',
        "Client doesn't know",
        'Client refused',
        'Data not collected',
    ],
    CLIENT_VOUCHER_TYPE: [
        'EHV',
        'FYI',
        'FUP',
        'HCV',
        'Homeless Set-Aside HCV',
        'Main Stream',
        'VASH',
        'Other',
    ],
    VOUCHER_TYPE: [
        'EHV',
        'FYI',
        'FUP',
        'HCV',
        'Homeless Set-Aside HCV',
        'Main Stream',
        'VASH',
        'None',
    ],
    VOUCHER_PHA: [
        'Anaheim Housing Authority',
        'Garden Grove Housing Authority',
        'Orange County Housing Authority',
        'Santa Ana Housing Authority',
    ],
    HOUSING_PROGRAM: [
        'Rapid Rehousing',
        'Scattered-Site Permanent Supportive Housing',
        'Housing Voucher',
        'Other',
    ],
    RECENT_LIVING_SITUATION: [
        'Unsheltered Homeless',
        'Sheltered Homeless',
        'Rental Unit',
        'Shared Housing',
        'Permanent Supportive Housing',
        'Other',
    ],
    BOOLEAN_OPTIONS: [
        { label: 'Yes', value: 'true' },
        { label: 'No', value: 'false' }
    ],  
    CLIENT_REFERRAL_PROCESS: ['Coordinated Entry', 'PHA', 'Other'],
    SERVICE_PROVIDER_AREA: ['North SPA', 'Central SPA', 'South SPA'],
    PHA_CONTRACT: [
        'Anaheim Housing Authority',
        'Garden Grove Housing Authority',
        'Orange County Housing Authority',
        'Santa Ana Housing Authority',
    ],
    US_STATE: ['AA','AE','AK','AL','AP','AR','AS','AZ','CA','CO','CT','DC','DE','FL','GA','GU','HI','IA',
        'ID','IL','IN','KS','KY','LA','MA','MD','ME','MI','MN','MO','MP','MS','MT','NC','ND','NE','NH','NJ',
        'NM','NV','NY','OH','OK','OR','PA','PR','RI','SC','SD','TN','TX','UT','VA','VI','VT','WA','WI','WV','WY',
    ],
    TAX_CLASSIFICATION_OPTIONS: [
        'Individual/sole proprietor or single-member LLC',
        'C Corporation',
        'S Corporation',
        'Partnership',
        'Trust/estate',
        'Limited liability company',
        'Other'
    ],
    TAX_EXEMPT_CODE_OPTIONS: [
        'C',
        'S',
        'P'
    ],
    TAX_PAYER_IDENTIFICATION_OPTIONS: [
        'Social Security Number (SSN)',
        'Employer identification number (EIN)',
    ],
    CREDITING_ACCOUNT_OPTIONS: [
        { label: 'CCD - Business Account', value: 'CCD' },
        { label: 'PPD - Personal Account', value: 'PPD' },
    ],
    TRANSACTION_STATUS_BADGE: [
        { value: 'New', badge: 'badge-new'},
        { value: 'Open', badge: 'badge-open'},
        { value: 'Needs Additional Information', badge: 'badge-nai'},
        { value: 'Payment Info Requested', badge: 'badge-pir'},
        { value: 'Approved for Payment', badge: 'badge-afp'},
        { value: 'Needs Additional Approval', badge: 'badge-naa'},
        { value: 'Payment Submitted', badge: 'badge-ps'},
        { value: 'Payment Info Received', badge: 'badge-py-received'},
        { value: 'Paid', badge: 'badge-paid'},
        { value: 'Denied', badge: 'badge-denied'},
        { value: 'Cancelled', badge: 'badge-cancelled'},
        { value: 'Payment Failure', badge: 'badge-rejected'},
        { value: 'Pending', badge: 'badge-pending'},
        { value: 'Additional Review Approved', badge: 'badge-additionalapproval'},
        { value: 'Additional Review Denied', badge: 'badge-additionaldenied'},
        { value: 'Pre-approved', badge: 'badge-preapproval'},
        { value: 'Stop Payment', badge: 'badge-stoppayment'},
        { value: 'Payment Returned', badge: 'badge-denied'},
        { value: 'Partial Payment Returned', badge: 'badge-partial-payment-return'},
        { value: 'Recursive Pending', badge: 'badge-open'},
        { value: 'Partially Paid', badge: 'badge-parially-paid'},
    ],
    IS_CLIENT_SCREENED : [
        'Yes',
        'No',
        'Data Not Collected'
    ],
    IS_RENTAL_ARREARS_OWED : [
        'Yes',
        'No',
        'Unknown'
    ],
    IS_UTILIZED_FULL_CALAIM : [
        'Yes',
        'No'        
    ],
    IS_UNCOVERED_UNDER_CALAIM : [
        'Yes',
        'No'        
    ],
    CASHPRO_UPLOAD_STATUS : [
        'Successful',
        'Failed'        
    ],
    COGNITO_GROUPS : [
        'FinanceGroup'
    ],
    DISTRIBUTION_TYPE : [
        { label: 'Reimbursement', value: 'Reimbursement' },
        { label: 'Third Party Payment', value: 'NonReimbursement' },
        { label: 'Direct Payments', value: 'DirectPayments' },
    ],
    //Note: When Added new column OR removed existing column, Please update below
    //'Value' attribute accordingly.
    RECONCILIATION_LIST_CHECKOPTION_EXTRA_COLUMNS: [
        { label: 'CheckNumber', value: '9' },
        { label: 'CheckCleared', value: '10' },
    ],
    TRANSACTION_COLOR_FLAG : [
        { label: 'New Transactions', value: 'New' },
        { label: 'Updated Transactions', value: 'Modified' }
    ],
    TRANSACTION_CLIENT_STATUS : [
        { label: 'Navigation', value: 'Navigation', type: 'WIT' },
        { label: 'Navigation/Deposits/Tenancy', value: 'Navigation/Deposits/Tenancy', type: 'WIT' },
        { label: 'Tenancy', value: 'Tenancy', type: 'WIT' }, 
        { label: 'HPSP/AssistOC District 1', value: 'HPSPDistrict1', type: 'HPSP' },
        { label: 'HPSP/AssistOC District 2', value: 'HPSPDistrict2', type: 'HPSP' },
        { label: 'HPSP/AssistOC District 3', value: 'HPSPDistrict3', type: 'HPSP' },
        { label: 'HPSP/AssistOC District 4', value: 'HPSPDistrict4', type: 'HPSP' },
        { label: 'HPSP/AssistOC District 5', value: 'HPSPDistrict5', type: 'HPSP' },
        { label: 'Upskill Client', value: 'UpskillClient', type: 'Upskill1,Upskill2' },
    ],
    CLIENT_LEASE_SIGNED : [
        'Yes',
        'No'        
    ],
    FUND_STATUS : [
        'Active',
        'Pending Start',
        'No Remaining Funds',        
        'Inactive'        
    ],
    FUND_SOURCES: [
        { label: 'Whatever It Takes', value: 'WIT'},
        { label: 'HPSP/AssistOC', value: 'HPSP'},
        { label: 'UpSkill OC – CalOptima', value: 'Upskill1'},
        { label: 'Upskill OC – Department of Veteran Affairs', value: 'Upskill2'},
    ],
    RECURSIVE_DURATION: [
        { label: '2 Month', value: '2' },
        { label: '3 Month', value: '3' },
        { label: '4 Month', value: '4' },
        { label: '5 Month', value: '5' },
        { label: '6 Month', value: '6' },
        { label: '7 Month', value: '7' },
        { label: '8 Month', value: '8' },
        { label: '9 Month', value: '9' },
        { label: '10 Month', value: '10' },
        { label: '11 Month', value: '11' },
        { label: '12 Month', value: '12' },
    ],
    RECURSIVE_FREQUENCY : [
        'One Time',
        'Recursive'
    ],
};
