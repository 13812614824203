import { Buffer } from 'buffer';
import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import './index.scss';
import 'bootstrap/dist/js/bootstrap.bundle.min';
import { configureAmplify } from './components/auth/amplifyConfig';
import App from './App';
import { UserContextProvider } from './store/userContext';
window.Buffer = Buffer;

configureAmplify();
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <UserContextProvider>
      <App />
    </UserContextProvider>
);
