import { Amplify, Storage } from "aws-amplify";

export function configureAmplify() {
    Amplify.configure({
        Auth: {
            identityPoolId: process.env.REACT_APP_IdentityPoolID,
            region: process.env.REACT_APP_Region,
            userPoolId: process.env.REACT_APP_UserPoolId,
            userPoolWebClientId: process.env.REACT_APP_UserPoolWebClientId,
        },
        Storage: {
            AWSS3: {
                bucket: process.env.REACT_APP_S3FrontendBucket, //REQUIRED -  Amazon S3 bucket
                region: process.env.REACT_APP_Region, //OPTIONAL -  Amazon service region
            }
        },
        API: {
            endpoints: [
                {
                    name: process.env.REACT_APP_APIGatewayName,
                    endpoint: process.env.REACT_APP_APIEndpoint
                }
            ]
        }
    });
}

//Configure Storage with S3 bucket information
export function setS3Config(bucket, level) {
    Storage.configure({
        bucket: bucket,
        level: level,
        region: process.env.REACT_APP_Region,
        identityPoolId: process.env.REACT_APP_IdentityPoolID
    });
}
