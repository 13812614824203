import { Auth } from "aws-amplify";
import signOutHandler from "./signOutHandler";

const getCurrentUser = async () => {
    let user = null;
    try{
        user = await Auth.currentAuthenticatedUser();
    }catch(e){
        console.log(e);
    }
    
    if(!user){
        signOutHandler();    
    }  

    return user;
}

export default getCurrentUser;
